<template>
  <div class="carbonReport">
    <div id="maintablepage">
      <div class="maintablepage-content">
        <button @click="handleOutput">生成PDF</button>
        <!-- 内容 -->
        <div class="contant_O pdf-panel" id="class_pdf">
          <headTitle :objAll="objAll"></headTitle>
          <directory :objAll="objAll" :nameList="nameList"></directory>
          <div class="maintablepage-header">
            <div class="maintablepage-header-name">碳排放报告</div>
          </div>
          <!-- 4.1 总碳排放 -->
          <div class="cont_z">
            <div class="msrStr">1. 总碳排放</div>
            <el-table class="app-table" :data="tableData" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="" align="left" prop="name" width="300">
              </el-table-column>
              <el-table-column label="本期" align="center" prop="codevalue">
              </el-table-column>
              <el-table-column label="同比" align="center" prop="YOY">
                <template slot-scope="scope">
                  <div style="display: flex;justify-content: center;">
                    <!-- 升 -->
                    <!-- <i class="el-icon-caret-top" style="color: red;font-size: 24px;line-height: 40px;"></i> -->
                    {{ scope.row.YOY }}
                    <!-- 降 -->
                    <!-- <i class="el-icon-caret-bottom" style="color: green;font-size: 24px;line-height: 40px;"></i> -->
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="同级别排名" align="center" prop="site">
              </el-table-column>
            </el-table>
            <div class="msrStr">碳排放构成如下：</div>
            <div style="width: 50%;height: 300px;margin: auto;" ref="chart1" id="chart1"></div>
          </div>
          <!-- 4.2 温室气体直接排放量表 -->
          <div class="cont_z">
            <div class="msrStr">2. 温室气体直接排放量表</div>
            <el-table class="app-table" :span-method="arraySpanMethod" :data="tableData1" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="源类别" align="left" prop="name" width="300">
              </el-table-column>
              <el-table-column label="温室气体本身质量（吨）" align="center" prop="type">
              </el-table-column>
              <el-table-column label="温室气体 CO2当量（吨 CO2）" align="center" prop="site">
              </el-table-column>
            </el-table>
          </div>
          <!-- 4.3 温室气体间接排放量表-->
          <div class="cont_z">
            <div class="msrStr">3. 温室气体间接排放量表</div>
            <el-table class="app-table" :span-method="arraySpan" :data="tableData2" border
              style="width: 100%;overflow: auto; margin: auto;margin-top: 16px;">
              <el-table-column label="源类别" align="left" prop="name" width="300">
              </el-table-column>
              <el-table-column label="温室气体本身质量（吨）" align="center" prop="type">
              </el-table-column>
              <el-table-column label="温室气体 CO2当量（吨 CO2）" align="center" prop="site">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!--  -->
        <div class="pdf-header"
          style="font-weight: bold; padding:15px 8px; width: 100%;  color: rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <!-- 页头 -->
        </div>
        <div class="pdf-footer"
          style="font-weight: bold; padding: 15px 8px; width: 70%; margin: 0 auto; border-top: 1px solid rgba(0, 0, 0, 0.85); position: fixed; top: -100vh;">
          <div style="display: flex; justify-content: center; align-items: center; padding-top: 5px;">
            页尾
          </div>
          <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
            第<div class="pdf-footer-page"></div>页 / 共<div class="pdf-footer-page-count"></div>页
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headTitle from './homePage.vue'
import directory from './directory.vue'
// import PdfLoader from './html2pdf.js'
import { outputPDF } from './outputPDF'
var echarts = require('echarts');
import "echarts/theme/macarons.js";
export default {
  components: { headTitle, directory },
  data() {
    return {
      tableData: [],
      tableData1: [
        // {
        //   name: '化石燃料燃烧 CO2 排放',
        //   type: '10.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '工业生产过程 CO2 排放',
        //   type: '21.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '工业生产过程 HFCs*排放',
        //   type: '10.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '工业生产过程 PFCs*排放',
        //   type: '21.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '工业生产过程 SF6 排放',
        //   type: '10.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '企业温室气体直接排放总量（tCO2e） ',
        //   type: '21.2%',
        //   site: '1.2%'
        // }
      ],
      tableData2: [
        // {
        //   name: '外购电力（kWh） ',
        //   type: '21.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '外购热力（GJ）',
        //   type: '21.2%',
        //   site: '1.2%'
        // },
        // {
        //   name: '企业温室气体间接排放总量（tCO2e ）',
        //   type: '21.2%',
        //   site: '1.2%'
        // }
      ],
      objAll: {},
      nameList: [
        {
          name: '碳排放报告',
          page: 2
        },
        {
          name: '总碳排放',
          page: 2
        },
        {
          name: '温室气体直接排放量表',
          page: 2
        },
        {
          name: '温室气体间接排放量表',
          page: 2
        }
      ],
    }
  },
  created() {
    let allQuery = this.$route.query
    this.objAll = {
      title: allQuery.regionName + allQuery.region2 + '系统',
      times: this.$moment(allQuery.date1).format('YYYY [年] ') + (allQuery.region3 == '天' ? this.$moment(allQuery.date1).format("MMM Do") : (allQuery.region3 == '月' ? (this.$moment(allQuery.date1).format("MMM [月]") + '度') : (allQuery.region3 + '度'))) + allQuery.region1,
      deptCode: allQuery.region,
      startDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).startOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).startOf("month").format('YYYY-MM-DD HH:mm:ss')),
      endDate: allQuery.region3 == '年' ? (this.$moment(allQuery.date1).endOf("year").format('YYYY-MM-DD HH:mm:ss')) : (this.$moment(allQuery.date1).endOf("month").format('YYYY-MM-DD HH:mm:ss')),
      queryType: 'month',
    }
    if (allQuery.region3 == '月') {
      this.objAll["queryType"] = "month"
    } else if (allQuery.region3 == '年') {
      this.objAll["queryType"] = "year"
    } else if (allQuery.region3 == '天') {
      this.objAll["queryType"] = "day1h"
      this.objAll.startDate = this.$moment(allQuery.date1).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.objAll.endDate = this.$moment(allQuery.date1).endOf('day').format('YYYY-MM-DD HH:mm:ss')
    }
    this.getAlldata()
    this.getTable()
  },
  mounted() { },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && rowIndex === 5) {
        return [1, 2];
      }
    },
    arraySpan({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 && rowIndex === 2) {
        return [1, 2];
      }
    },
    // handleExport() {
    //   this.pdfDownLoader.outPutPdfFn('碳排放报告')
    //   let vm = this
    //   setTimeout(() => {
    //     vm.uploadreport()
    //   }, 10000);
    // },
    handleOutput() {
      let _this = this;
      const element = document.querySelector('.pdf-panel');
      const header = document.querySelector('.pdf-header');
      const footer = document.querySelector('.pdf-footer');
      outputPDF({
        element: element,
        footer: footer,
        header: header,
        contentWidth: 500,
        filename: '碳排放报告',
        _this: _this,
        dev_url: Config.dev_url
      })
    },
    getAlldata() {
      let data = { "code": this.objAll.deptCode, "type": this.objAll.queryType, "starttime": this.objAll.startDate, "endtime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/construct";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        this.initEchart(allData)
        var cousts = 0
        allData.forEach((item) => {
          cousts += Number(item.value)
        })
        this.tableData.push({
          name: '总碳排放（万吨）',
          codevalue: cousts,
          YOY: '',
          site: ''
        })
      });
    },
    getTable() {
      let data = { "code": this.objAll.deptCode, "type": this.objAll.queryType, "starttime": this.objAll.startDate, "endtime": this.objAll.endDate }
      let url = Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/intensity";
      this.$axios.post(url, data).then((res) => {
        let allData = res.data.data;
        allData.forEach((item) => {
          if (item.name == '当前值') {
            this.tableData.push({
              name: '碳排放强度（吨/万元）',
              codevalue: item.value,
              YOY: '',
              site: ''
            })
          }
        });
      });
    },
    // 饼图
    initEchart(allData) {
      let chartInit = echarts.init(this.$refs.chart1, "chart1");
      var syjgdata = allData
      var colorList = ["#4DB8FF", "#2D76FE", "#6E93FF", "#5FEBFF", "#8AF1FF", "#8AF1FF", "#FFB200", "#3E6DFF", "#536EBF"];
      var option13 = {
        backgroundColor: 'white',
        legend: {
          left: "center",
          bottom: '5%',
        },
        grid: {
          left: "20%",
        },
        series: [{
          itemStyle: {
            normal: {
              color: function (params) {
                return colorList[params.dataIndex]
              },
              borderColor: '#172659',
              borderWidth: 0
            }
          },
          type: 'pie',
          center: ["50%", "40%"],
          labelLine: {
            normal: {
              lineStyle: {
                color: '#34569D'
              }
            }
          },
          label: {
            normal: {
              show: true,
              formatter: params => {
                return params.percent.toFixed(0) + '%';
              },
              position: 'inner',
            }
          },
          data: syjgdata
        }]
      }

      chartInit.setOption(option13);
    }
  }
}
</script>
<style lang="less">
.carbonReport {
  background: #fff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

#maintablepage {
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
}

#maintablepage .maintablepage-content .maintablepage-header {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .maintablepage-header-name {
    font-size: 38px;
  }
}

#maintablepage .maintablepage-content {
  flex: auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.app-table .el-table__header thead tr th {
  padding: 0;
  height: 40px;
  background: #f2f3f5;
}
</style>
<style lang="less" scoped>
#maintablepage .maintablepage-content {
  overflow-y: auto !important;
}

.contant_O {
  overflow-y: scroll;
  position: relative;
}

.cont_z {
  padding: 10px 0;
  font-size: 30px;
}

/deep/.app-table .el-table__header thead tr th .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

/deep/.app-table .el-table__body tbody tr td .cell {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

.msrStr {
  margin: 20px 0;
}
</style>