import { render, staticRenderFns } from "./carbonReport.vue?vue&type=template&id=1b8c49b2&scoped=true"
import script from "./carbonReport.vue?vue&type=script&lang=js"
export * from "./carbonReport.vue?vue&type=script&lang=js"
import style0 from "./carbonReport.vue?vue&type=style&index=0&id=1b8c49b2&prod&lang=less"
import style1 from "./carbonReport.vue?vue&type=style&index=1&id=1b8c49b2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8c49b2",
  null
  
)

export default component.exports